import React from 'react';
import {Col} from 'react-bootstrap';
import './CenterModule.css';
import AboutMe from '../../sections/AboutMe/AboutMe';
import Experience from '../../sections/Experience/Experience';
import Portfolio from '../../sections/Portfolio/Portfolio';
import Contact from '../../sections/Contact/Contact';
import Offer from '../../sections/Offer/Offer';

import { useActiveSection } from '../../ActiveSectionContext';

const CenterModule = () => {

    const { activeSection } = useActiveSection();

    return (
        <Col className="col-sm-12 col-md-12 col-lg-8 col-xl-7 order-xl-2">
            <main className='center-module'>
                <AboutMe isActive={activeSection === 'about-me'} />
                <Offer isActive={activeSection === 'offer'} />
                <Experience isActive={activeSection === 'experience'} />
                <Portfolio isActive={activeSection === 'portfolio'} />
                <Contact isActive={activeSection === 'contact'} />
            </main>
        </Col>

    );
};

export default CenterModule;
