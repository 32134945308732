import React from 'react';
import './Experience.css';

const SmartNet = () => {
    return (
        <div className='exp-item'>
            <div className='exp-header-item'>
                <div className='exp-left-side'>
                    <h5>Usługi Informatyczne Smart Net</h5>
                    <span>Tworzenie witryn internetowych</span>
                </div>
                <div className='exp-right-side'>
                    <span className='data'>02.2013 - 05.2015</span>
                </div>
            </div>
            <div>
                <p>Tworzenie stron internetowych w Wordpress. Mini modyfikacje PHP. Zarządzanie i tworzenie sklepów internetowych.</p>
            </div>

        </div>
    );
};

const EpaSystemy = () => {
    return (
        <div className='exp-item'>
            <div className='exp-header-item'>
                <div className='exp-left-side'>
                    <h5>EPA Systemy Sp. z o.o.</h5>
                    <span>Inżynier storage | Key Account Manager</span>
                </div>
                <div className='exp-right-side'><span className='data'>10.2015 - 02.2016 - Inżynier storage <br></br> 02.2016 - 12.2016 - Key Account Manager</span></div>
            </div>
            <div>
                <p>Wsparcie techniczne dot. systemów storage dla klientów dystrybucji EPA Systemy.</p>
                <p>Wsparcie techniczne dot. urządzeń sieciowych.</p>
                <p>Administracja serwisami internetowymi. Dodatkowo: modernizacja portali internetowych w dystrybucji.</p>
                <p>Tworzenie landing page's, newsletterów oraz akcji promocyjnych.</p>
                <p>Reprezentowanie firmy na wydarzeniach / eventach.</p>
                <p>Sprzedaż urządzeń sieciowych i macierzy dyskowych.</p>
                <p>Prowadzone webinaria oraz szkolenia dla pracowników / klientów. </p>
            </div>
        </div>
    );
};

const StorageIT = () => {
    return (
        <div className='exp-item'>
            <div className='exp-header-item'>
                <div className='exp-left-side'>
                    <h5>Storage IT Sp. z o.o.</h5>
                    <span>Dyrektor Handlowy | Doradca techniczny</span>
                </div>
                <div className='exp-right-side'><span className='data'>01.2017 - 06.2020</span></div>
            </div>
            <div>
                <p>Zarządzanie zespołem sprzedażowym z wielkimi sukcesami (wdrożenia w Teatrze Wielkim, zakładach chemicznych, policji, wojsku, placówkach medycznych i wiele innych).</p>
                <p>Wsparcie techniczne dot. urządzeń sieciowych oraz macierzy dyskowych dla kluczowych klientów.</p>
                <p>Administracja portalami/sklepami internetowymi w firmie. </p>
                <p>Reprezentowanie firmy na wydarzeniach / eventach.</p>
            </div>
        </div>
    );
};

const Radimar = () => {
    return (
        <div className='exp-item'>
            <div className='exp-header-item'>
                <div className='exp-left-side'>
                    <h5>RADIMAR.PL</h5>
                    <span>Główny informatyk</span>
                </div>
                <div className='exp-right-side'><span className='data'>07.2020 - 04.2021</span></div>
            </div>
            <div>
                <p>Pomoc w prowadzeniu strategii informatycznej w firmie.</p>
                <p>Projektowanie aplikacji / stron internetowych oraz materiałów graficznych na potrzeby firmy. </p>
                <p>Reprezentowanie firmy na wydarzeniach / eventach.</p>
            </div>
        </div>
    );
};

const IdoSell = () => {
    return (
        <div className='exp-item'>
            <div className='exp-header-item'>
                <div className='exp-left-side'>
                    <h5>IdoSell</h5>
                    <span>Wsparcie techniczne - aplikacje pomocnicze, systemy ERP</span>
                </div>
                <div className='exp-right-side'><span className='data'>czerwiec 2021</span></div>
            </div>
            <div>
                <p>Pomoc konfiguracyjna z zakresu aplikacji pomocniczych, systemów ERP.</p>
            </div>
        </div>
    );
};

const Apline = () => {
    return (
        <div className='exp-item'>
            <div className='exp-header-item'>
                <div className='exp-left-side'>
                    <h5>APLINE - Własna działalność</h5>
                    <span>Właściciel</span>
                </div>
                <div className='exp-right-side'><span className='data'>marzec 2016 do teraz</span></div>
            </div>
            <div>
                <p>Zakres z oferty. Główna działalność - projektowanie stron internetowych oraz aplikacji / doradztwo techniczne z zakresu IT. </p>
            </div>
        </div>
    );
};


const Experience = ({ isActive }) => {
    return (
        <div id='experience' className={isActive ? 'active' : 'hidden'}>
            <h3 className='title'>Doświadczenie</h3>
            <Apline />
            <IdoSell />
            <Radimar />
            <SmartNet />
            <StorageIT />
            <EpaSystemy />
        </div>
    );
};

export default Experience;