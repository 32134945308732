import React, { useState } from 'react';
import './Header.css';

import { ReactComponent as MoonIcon } from '../../assets/icons/moon.svg';
import { ReactComponent as SunIcon } from '../../assets/icons/sunny.svg';

import { useActiveSection } from '../../ActiveSectionContext';

const Header = () => {

    const redirectToHome = () => {
        window.location.href = '/';
    };

    const { activeSection, setActiveSection } = useActiveSection();

    const handleNavigationClick = (sectionId) => {
      setActiveSection(sectionId);
    };

    const getIconStyle = (sectionId) => {
        return activeSection === sectionId ? 'nav-icon active-icon' : 'nav-icon';
      };
  
      const getLinkStyle = (sectionId) => {
        return activeSection === sectionId ? 'nav-item aside-icon active-link' : 'nav-item aside-icon';
      };

    // Zdefiniowanie zmiennej i funkcji ustawiania motywu
    const [isDarkMode, setIsDarkMode] = useState(false);

    const toggleDarkMode = () => {
        setIsDarkMode(!isDarkMode);
        if (!isDarkMode) {
            document.body.classList.add('dark-mode');
        } else {
            document.body.classList.remove('dark-mode');
        }
    };    

    return (
        <nav className="navbar navbar-light bg-transparent">
            <a className="navbar-brand" href="#" onClick={redirectToHome}>
                <h1 className='logo' title='Informatyk'>Arkadiusz Pielechowski</h1>
            </a>
            <div className='ml-auto'>
                <div className="theme-icon" onClick={toggleDarkMode}>
                    {isDarkMode ? (
                        <MoonIcon style={{ fill: 'black' }} />
                    ) : (
                        <SunIcon style={{ fill: 'white' }} />
                    )}
                </div>
                <button
                    className="navbar-toggler d-xl-none hamburger"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded="false"
                    aria-label="Nawigacja"
                >
                    <span className="navbar-toggler-icon hamburger-span"></span>
                </button>
            </div>

            <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <a href="#about-me" className={`nav-link ${getLinkStyle('about-me')}`} onClick={() => handleNavigationClick('about-me')}>O mnie</a>
                    </li>
                    <li className="nav-item">
                    <a href="#offer" className={`nav-link ${getLinkStyle('offer')}`} onClick={() => handleNavigationClick('offer')}>Oferta</a>
                    </li>
                    <li className="nav-item">
                        <a href="#experience" className={`nav-link ${getLinkStyle('experience')}`} onClick={() => handleNavigationClick('experience')}>Doświadczenie</a>
                    </li>
                    <li className="nav-item">
                        <a href="#portfolio" className={`nav-link ${getLinkStyle('portfolio')}`} onClick={() => handleNavigationClick('portfolio')}>Porfolio</a>
                    </li>
                    <li className="nav-item">
                    <a href="#contact" className={`nav-link ${getLinkStyle('contact')}`} onClick={() => handleNavigationClick('contact')}>Contact</a>
                    </li>
                </ul>
            </div>

        </nav>
    );
};

export default Header;
