import React, { useState } from 'react';
import './Contact.css';
import { Button, Form } from 'react-bootstrap';
import { FaUser, FaPhone, FaEnvelope, FaComment } from 'react-icons/fa';

const Contact = ({ isActive }) => {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        const response = await fetch('https://apline.pl/send-email.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },            
            body: JSON.stringify({ name, phone, email, message }),
        });
        const data = await response.json();
        alert(data.message);
    };

    return (
        <div id='contact' className={isActive ? 'active' : 'hidden'}>
            <h3 style={{paddingBottom: "20px"}} className='title'>Formularz kontaktowy</h3>
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formName">
                    <div className="input-icon">
                        <FaUser fill='var(--active-blue)' />
                        <Form.Control type="text" placeholder="Nazwa firmy / Imię" value={name} onChange={(e) => setName(e.target.value)} />
                    </div>
                </Form.Group>
                <Form.Group controlId="formPhone">
                    <div className="input-icon">
                        <FaPhone fill='var(--active-blue)' />
                        <Form.Control type="tel" placeholder="Telefon" value={phone} onChange={(e) => setPhone(e.target.value)} />
                    </div>
                </Form.Group>
                <Form.Group controlId="formEmail">
                    <div className="input-icon">
                        <FaEnvelope fill='var(--active-blue)' />
                        <Form.Control required type="email" placeholder="Wpisz adres e-mail" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                </Form.Group>
                <Form.Group controlId="formMessage">
                    <div className="input-icon">
                        <Form.Control as="textarea" placeholder="Treść wiadomości..." rows={3} value={message} onChange={(e) => setMessage(e.target.value)} />
                    </div>
                </Form.Group>
                <Button className='contact-btn' variant="primary" type="submit">
                    Wyślij wiadomość
                </Button>
            </Form>
        </div>
    );
};

export default Contact;
