import React from 'react';
import './Offer.css';

const StronyInternetowe = () => {
    return (
        <div className='cecha'>
            <h2>Strony Internetowe 🌐</h2>
            <p className='cecha-naglowek'>Kompleksowe Rozwiązania od A do Z</p>
            <p>Specjalizuję się w tworzeniu responsywnych i efektywnych stron internetowych. Od projektu graficznego, przez kodowanie, aż po administrację - wszystko w jednym miejscu.</p>
            <p>🛠️ <strong>Technologie:</strong> JS, PHP, React, Laravel, HTML, Wordpress</p>
            <p>🌟 <strong>Całościowe podejście:</strong> Front-end i Back-end</p>
        </div>
    );
};

const SklepyInternetowe = () => {
    return (
        <div className='cecha'>
            <h2>Sklepy Internetowe 🛒</h2>
            <p className='cecha-naglowek'>Twój Sklep, Twoje Zasady</p>
            <p>Oferuję modyfikację oraz tworzenie interfejsów sklepów internetowych zintegrowanych z najnowszymi narzędziami do zarządzania zamówieniami i płatnościami.</p>
            <p>🚀 <strong>Automatyzacja:</strong> Programy automatyzujące, API, Wtyczki</p>
            <p>🔒 <strong>Bezpieczeństwo:</strong> Konfiguracja serwera, zabezpieczenia aplikacji</p>
            <p>🛠️ <strong>Technologie:</strong> PHP, .NET, JS</p>
        </div>
    );
};

const Aplikacje = () => {
    return (
        <div className='cecha'>
            <h2>Aplikacje 📱</h2>
            <p className='cecha-naglowek'>Innowacyjność na Wyciągnięcie Ręki</p>
            <p>Tworzę aplikacje na różne platformy, z różnymi technologiami. Od aplikacji webowych po narzędzia automatyzujące.</p>
            <p>🔒 <strong>Prywatność:</strong> Na github większość projektów posiadam prywatnych</p>
            <p>🌐 <strong>Portfolio:</strong> Udostępniam projekt tej strony (Technologia React.js)</p>
            <p>Github: [link] | Portfolio: [projekty] </p>
        </div>
    );
};

const Grafika = () => {
    return (
        <div className='cecha'>
            <h2>Grafika 🎨</h2>
            <p className='cecha-naglowek'>Estetyka, która Sprzedaje</p>
            <p>Projektuję nowoczesne interfejsy i skórki, które są nie tylko ładne, ale też użyteczne.</p>
            <p>🚀  <strong>Trendy: </strong> Zgodność z najnowszymi praktykami i technologiami</p>
        </div>
    );
};

const DoradztwoTechniczne = () => {
    return (
        <div className='cecha'>
            <h2>Doradztwo Techniczne 🛠️</h2>
            <p className='cecha-naglowek'>Optymalizacja Twojego Biznesu</p>
            <p>Oferuję kompleksowe doradztwo techniczne w zakresie infrastruktury IT. Od doboru sprzętu, przez konfigurację, aż po wsparcie po wdrożeniu.</p>
            <p>💼   <strong>Zakres:</strong> Sieci, Serwery, Kopie zapasowe</p>
            <p>🔒   <strong>Bezpieczeństwo:</strong> SAN/NAS</p>
        </div>
    );
};



const Offer = ({ isActive }) => {
    return (
        <div id='offer' className={isActive ? 'active' : 'hidden'}>
            <h3 className='title'>Moja oferta</h3>
            <p>Zachęcam do zapoznania się z moją ofertą oraz wyboru usług, które najlepiej odpowiadają Twoim potrzebom. Jeśli masz jakiekolwiek pytania lub wątpliwości, konsultacje są bezpłatne. Skontaktuj się ze mną, abyśmy mogli razem znaleźć najlepsze rozwiązanie dla Ciebie lub Twojego biznesu.</p>
            <div className='line'></div>
            <StronyInternetowe />
            <Aplikacje />
            <SklepyInternetowe />           
            <Grafika />
            <DoradztwoTechniczne />
        </div>
    );
};

export default Offer;