import React from 'react';
import './AboutMe.css';
import { Row, Col } from 'react-bootstrap';

const AboutMe = ({ isActive }) => {
    return (
        <div id='about-me' className={isActive ? 'active' : 'hidden'}>
            <div>
                <h1 className='title'>O mnie</h1>
            </div>
            <p>Cześć, jestem Arek i od ponad 10 lat działam w branży IT. Specjalizuję się w tworzeniu stron internetowych, programów webowych oraz aplikacji .NET (Windows). Moje największe projekty to budowa witryn internetowych z wykorzystaniem technologii HTML, CSS, JS i PHP, a także projektowanie wyglądu sklepów internetowych.</p>
            <Row>
                <Col className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                    <div className='cecha'>
                        <p><strong>Co mnie wyróżnia?</strong></p>
                        <p>Po pierwsze, jestem jedyną osobą, z którą będziesz rozmawiać od początku do końca projektu. Nie ma tu żadnych pośredników czy zewnętrznych firm – zajmuję się wszystkim osobiście.</p>
                        <p>Po drugie, mam bogate doświadczenie nie tylko jako programista, ale również jako specjalista od infrastruktury sieciowej. To pozwala mi na pełne zrozumienie Twojego projektu od strony technicznej.</p>

                    </div>

                </Col>
                <Col className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                    <div className='cecha'>
                        <p>Jestem bardzo kontaktowy i zawsze dostępny telefonicznie. Nie krępuję się dzwonić i dopytywać o szczegóły, co gwarantuje, że projekt będzie realizowany zgodnie z Twoimi oczekiwaniami. Wybierając mnie, zyskujesz nie tylko eksperta w dziedzinie IT, ale również partnera, który zrozumie Twoje potrzeby i przekształci je w rzeczywistość.</p>
                        <p>Zapraszam do kontaktu 😊</p>
                    </div>
                </Col>
            </Row>

        </div>
    );
};

export default AboutMe;