import React, { useState } from 'react';
import './Portfolio.css';
import { Container, Row, Col, Image, Modal } from 'react-bootstrap';

const Portfolio = ({ isActive }) => {

    const [show, setShow] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');

    const handleClose = () => setShow(false);
    const handleShow = (image) => {
        setSelectedImage(image);
        setShow(true);
    };

    const images = [
        'ulotka1_wynik.webp',
        'chmserwis_wynik.webp',
        'szkola-podstawowa_wynik.webp',
        'nxlt_wynik.webp',
        'ranczo2_wynik.webp',
        'csharp_wynik.webp',
        'paprikana_wynik.webp',
        'uslugi-wykonczeniowe_wynik.webp',
        'pcstorage_wynik.webp',
        'chmserwis2_wynik.webp',
        'ulotka3_wynik.webp',
        'storeservice_wynik.webp',
        'makeittogether_wynik.webp',
        'paprikana2_wynik.webp',
        'piekarniajasmin_wynik.webp',
        'eurosim_wynik.webp',
        'nxlt2_wynik.webp',
        'dziadeccy_wynik.webp',
        'ranczo_wynik.webp',
        'essenzadoliva_wynik.webp',
        'ulotka2_wynik.webp',
        'chmserwis3_wynik.webp'
    ];


    return (
        <div className={isActive ? 'active' : 'hidden'}>
            <Container id='portfolio'>
                <h3 className='title'>Portfolio</h3>
                <p>Ostatnio wykonana skórka graficzna sklepu internetowego: </p>
                <p>🎨 <a href='https://www.sklepwnetrz.pl/' target="_blank">sklepwnetrz.pl</a></p>
                <p>To tylko część moich projektów:</p>
                <Row>
                    {images.map((image, index) => (
                        <Col xs={6} md={6} lg={6} key={index}>
                            <Image
                                src={image}
                                thumbnail
                                onClick={() => handleShow(image)}
                                style={{ cursor: 'pointer' }}
                            />
                        </Col>
                    ))}
                </Row>

                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Podgląd obrazu</Modal.Title>
                        <span
                            style={{ cursor: 'pointer', marginLeft: '10px' }}
                            onClick={() => window.open(selectedImage, '_blank')}
                        >
                            🖥️
                        </span>
                    </Modal.Header>
                    <Modal.Body>
                        <Image src={selectedImage} fluid />
                    </Modal.Body>
                </Modal>
            </Container>
        </div>

    );
};

export default Portfolio;