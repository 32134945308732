import React from 'react';
import { Col } from 'react-bootstrap';
import './RightSidebar.css';

import { ReactComponent as AboutMeIcon } from '../../assets/icons/profile.svg';
import { ReactComponent as OfferIcon } from '../../assets/icons/offer.svg';
import { ReactComponent as ExperienceIcon } from '../../assets/icons/exp.svg';
import { ReactComponent as PortfolioIcon } from '../../assets/icons/code.svg';
import { ReactComponent as ContactIcon } from '../../assets/icons/contact.svg';

import { useActiveSection } from '../../ActiveSectionContext';

const RightSidebar = () => {

    const { activeSection, setActiveSection } = useActiveSection();

    const handleNavigationClick = (sectionId) => {
        setActiveSection(sectionId);
    };

    const getIconStyle = (sectionId) => {
        return activeSection === sectionId ? 'nav-icon active-icon' : 'nav-icon';
    };

    const getLinkStyle = (sectionId) => {
        return activeSection === sectionId ? 'nav-item aside-icon active-link' : 'nav-item aside-icon';
    };

    return (
        <Col className="d-none d-xl-block col-xl-2 order-xl-3">
            <aside className='right-sidebar'>
                <div className='right-icons'>
                    <a href="#about-me" className={getLinkStyle('about-me')} onClick={() => handleNavigationClick('about-me')}>
                        <AboutMeIcon className={getIconStyle('about-me')} style={{ fill: 'var(--bg-icon)' }} />
                        <span>O mnie</span>
                    </a>
                    <a href="#offer" className={getLinkStyle('offer')} onClick={() => handleNavigationClick('offer')}>
                        <OfferIcon className={getIconStyle('offer')} style={{ fill: 'var(--bg-icon)' }} />
                        <span>Oferta</span>
                    </a>
                    <a href="#experience" className={getLinkStyle('experience')} onClick={() => handleNavigationClick('experience')}>
                        <ExperienceIcon className={getIconStyle('experience')} style={{ fill: 'var(--bg-icon)' }} />
                        <span>Doświadczenie</span>
                    </a>
                    <a href="#portfolio" className={getLinkStyle('portfolio')} onClick={() => handleNavigationClick('portfolio')}>
                        <PortfolioIcon className={getIconStyle('portfolio')} style={{ fill: 'var(--bg-icon)' }} />
                        <span>Portfolio</span>
                    </a>
                    <a href="#contact" className={getLinkStyle('contact')} onClick={() => handleNavigationClick('contact')}>
                        <ContactIcon className={getIconStyle('contact')} style={{ fill: 'var(--bg-icon)' }} />
                        <span>Kontakt</span>
                    </a>
                </div>
            </aside>
        </Col>
    );
};

export default RightSidebar;
