import React from 'react';
import { Col } from 'react-bootstrap';
import './LeftSidebar.css';

import profileImage from '../../assets/images/profile.webp';
import { ReactComponent as FbIcon } from '../../assets/icons/fb.svg';
import { ReactComponent as LinIcon } from '../../assets/icons/lin.svg';
import { ReactComponent as WppIcon } from '../../assets/icons/wapp.svg';
import { ReactComponent as WhatsappIcon }  from '../../assets/icons/whatsapp.svg';
import { ReactComponent as MailIcon } from '../../assets/icons/email.svg';
import { ReactComponent as MailIcon2 }  from '../../assets/icons/mail.svg';
import { ReactComponent as PhoneIcon } from '../../assets/icons/phone.svg';

const LeftSidebar = () => {
  return (
    <Col className="col-sm-12 col-md-12 col-lg-4 col-xl-3 order-xl-1">
      <div className='left-sidebar'>
        <img
          src={profileImage}
          alt="Arkadiusz Pielechowski"
          className="profile-image"
          loading="lazy" 
        />
        <h1>Arkadiusz Pielechowski<span>Informatyk<br></br>Full-stack Developer</span></h1>
        <div className="social-media">
          <a href="https://www.facebook.com/arek.pielechowski/" className="nav-item">
            <FbIcon style={{ fill: 'var(--bg-icon)' }} />
          </a>
          <a href="https://www.linkedin.com/in/arkadiusz-pielechowski-26b094147" className="nav-item">
            <LinIcon style={{ fill: 'var(--bg-icon)' }} />
          </a>
          <a href="tel:+48665080312" className="nav-item">
            <WppIcon style={{ fill: 'var(--bg-icon)' }} />
          </a>
          <a href="mailto:arkadiusz@apline.pl" className="nav-item">
            <MailIcon style={{ fill: 'var(--bg-icon)' }} />
          </a>
        </div>
        <div className="contact-info">
          <div className='contact-label'>
            <div className='contact-icon'>
              <PhoneIcon style={{ fill: 'var(--bg-icon)' }} />
            </div>
            <div className='contact-text'>
              <p>Telefon:</p>
              <a href='tel:|48665080312'>+48 665 080 312</a>
            </div>
          </div>
          <div className='line'></div>
          <div className='contact-label'>
            <div className='contact-icon'>
              <MailIcon2 />
            </div>
            <div className='contact-text'>
              <p>Mail:</p>
              <a href='tel:mailto:arkadiusz@apline.pl'>arkadiusz@apline.pl</a>
            </div>
          </div>
          <div className='line'></div>
          <div className='contact-label'>
            <div className='contact-icon'>
              <WhatsappIcon />
            </div>
            <div className='contact-text'>
              <p>Whatsapp:</p>
              <a href='whatsapp'>@665080312</a>
            </div>
          </div>
        </div>
        { /* <Button className='button-cv' variant="primary" href="path/to/your/CV.pdf" download>Pobierz CV</Button> */}
      </div>

    </Col>
  );
};

export default LeftSidebar;
