//import logo from './profile.png';
import Header from './components/Header/Header';
import LeftSidebar from './components/LeftSidebar/LeftSidebar';
import RightSidebar from './components/RightSidebar/RightSidebar';
import CenterModule from './components/CenterModule/CenterModule';
import './styles/global.css';

import { ActiveSectionProvider } from './ActiveSectionContext';

import './App.css';
import { Container, Row } from 'react-bootstrap';

function App() {
  return (
    <ActiveSectionProvider>
      <div className="App">
        <Container id='portfolio-app'>
          <Row row>
            <Header />
          </Row>
          <Row row className='portfolio-row'>
            <LeftSidebar />
            <CenterModule />
            <RightSidebar />
          </Row>
        </Container>
      </div>
    </ActiveSectionProvider>
  );
}

export default App;
